import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { ACCESS_TOKEN, REFRESH_TOKEN, TOKEN_EXPIRY_TIME } from '../constants/localStorage';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { AuthContextType } from '../types/AuthContext';
import { USER_PERMISSIONS } from '../types';

export const AuthContext = createContext({} as AuthContextType);

export const AuthProvider = ({ children }: { children: JSX.Element }) => {
  const [accessToken, setAccessToken] = useLocalStorage(ACCESS_TOKEN, null);
  const [refreshToken, setRefreshToken] = useLocalStorage(REFRESH_TOKEN, null);
  const [roleSpecificId, setRoleSpecificId] = useLocalStorage('roleSpecificId', null);
  const [roleType, setRoleType] = useLocalStorage('roleType', null);
  const [userId, setUserId] = useLocalStorage('userId', null);
  const [isAuthed, setIsAuthed] = useState(!!accessToken);
  const [profileIconUrl, setProfileIconUrl] = useState('');
  const [isUserActive, setIsUserActive] = useLocalStorage('isUserActive', null);
  const [tokenExpiryTime, setTokenExpiryTime] = useLocalStorage(TOKEN_EXPIRY_TIME, null);
  const [userPermissions, setUserPermissions] = useLocalStorage('userPermission', []);

  const permissionCheck = useCallback(
    (permissionType: USER_PERMISSIONS) => {
      return userPermissions?.some(
        (singlePermission: USER_PERMISSIONS) => singlePermission === permissionType
      );
    },
    [userPermissions]
  );

  const contextData = useMemo(
    () => ({
      isAuthed,
      setIsAuthed,
      accessToken,
      setAccessToken,
      refreshToken,
      setRefreshToken,
      roleSpecificId,
      setRoleSpecificId,
      roleType,
      setRoleType,
      userId,
      setUserId,
      profileIconUrl,
      setProfileIconUrl,
      isUserActive,
      setIsUserActive,
      tokenExpiryTime,
      setTokenExpiryTime,
      userPermissions,
      setUserPermissions,
      permissionCheck
    }),
    [
      isAuthed,
      setIsAuthed,
      accessToken,
      setAccessToken,
      refreshToken,
      setRefreshToken,
      roleSpecificId,
      setRoleSpecificId,
      roleType,
      setRoleType,
      userId,
      setUserId,
      profileIconUrl,
      setProfileIconUrl,
      isUserActive,
      setIsUserActive,
      tokenExpiryTime,
      setTokenExpiryTime,
      userPermissions,
      setUserPermissions,
      permissionCheck
    ]
  );

  return <AuthContext.Provider value={contextData}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('Use Auth should be used inside Auth Context Provider');
  }
  return context;
};
