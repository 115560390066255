const STORE = '/store';
const STUDIO = '/studio';
const LOGIN_CREDENTIALS = '/login-credentials';
const SELLER = '/seller';
const HOST = '/host';

const ORDER = '/order';
export const SUNEIFY = '/suneify';
export const ROUTES = {
  HOME: '/',
  STORE: `${STORE}`,
  STUDIO: `${STUDIO}`,
  PRODUCT: `${STORE}/product`,
  ORDER: `${STORE}/order`,
  LIVE: `${STUDIO}/live`,
  LIVESTREAM: `${STUDIO}/live/:eventId`,
  VIDEOS: `${STUDIO}/videos`,
  SIGNUP: '/signup',
  SIGNUP_SELLER: '/signup/seller',
  SIGNUP_HOST: '/signup/host',
  LOGIN: '/login',
  RESET_PASSWORD_REQUEST: '/reset-password/request',
  RESET_PASSWORD_OTP_VERIFICATION: '/reset-password/verify',
  RESET_PASSWORD_UPDATE: '/reset-password/update',
  UNAUTHORIZED: '/401',
  NOT_FOUND: '/404',
  LOGIN_CREDENTIALS: `${LOGIN_CREDENTIALS}`,
  BUSINESS_INFORMATION: `${LOGIN_CREDENTIALS}/business-information${SELLER}`,
  ADDITIONAL_INFORMATION: `${LOGIN_CREDENTIALS}/additional-information${SELLER}`,
  SELLER: `${SELLER}`,
  HOST: `${HOST}`,
  SELLER_ACCOUNT_INFORMATION: `${SELLER}/account-information`,
  SELLER_BUSINESS_INFORMATION: `${SELLER}/business-information`,
  SELLER_ADDITIONAL_INFORMATION: `${SELLER}/additional-information`,
  SELLER_COPYRIGHT: `${SELLER}/copyright`,
  SELLER_TERMS_OF_SERVICE: `${SELLER}/terms-of-service`,
  STREAM_STATUS: '/status',
  //TODO : remove after the actual url is available
  HOST_LOGIN: '/host/storefront',
  HOST_PERSONAL_INFORMATION: `${LOGIN_CREDENTIALS}/personal-information${HOST}`,
  HOST_ADDITIONAL_INFORMATION: `${LOGIN_CREDENTIALS}/additional-information${HOST}`,
  HOST_ACCOUNT_INFORMATION: `${HOST}/account-information`,
  HOST_EDIT_PERSONAL_INFORMATION: `${HOST}/personal-information`,
  HOST_EDIT_ADDITIONAL_INFORMATION: `${HOST}/additional-information`,
  HOST_COPYRIGHT: `${HOST}/copyright`,
  HOST_TERMS_OF_SERVICE: `${HOST}/terms-of-service`,
  TERMS_OF_SERVICE: '/terms-of-service',
  COMMUNITY_GUIDELINES: '/community-guidelines',
  PRIVACY_STATEMENT: '/privacy-statement',
  HEALTH_CHECK: '/health-check',

  SUNEIFY: '/suneify',
  SUNEIFY_PRODUCTS: `${SUNEIFY}/store/products`,
  // SUNEIFY_PRODUCTS_CREATE: `${SUNEIFY}/store/products/create`,
  // SUNEIFY_PRODUCTS_VIEW: `${SUNEIFY}/store/products/view/:productId`,
  SUNEIFY_PRODUCTS_CREATE: `${SUNEIFY}/store/products/:mode`,
  // endpoint for edit and create product
  SUNEIFY_PRODUCTS_VIEW_EDIT: `${SUNEIFY}/store/products/:mode/:productId`,

  SUNEIFY_ORDERS: `${SUNEIFY}/store${ORDER}`,
  SUNEIFY_ORDER_SUMMARY: `${SUNEIFY}/store${ORDER}/order-summary`,
  SUNEIFY_VIDEOS: `${SUNEIFY}/studio/videos`,
  SUNEIFY_LIVE: `${SUNEIFY}/studio/live`,
  SUNEIFY_SELLER_INFO: `${SUNEIFY}${SELLER}/account-information`,
  SUNEIFY_ONBOARDING: `${SUNEIFY}${SELLER}/onboarding`,
  SUNEIFY_SELLER_INFO_EDIT: `${SUNEIFY}${SELLER}/account-information/edit`,
  // Admin routes
  SUNEIFY_ADMIN: `${SUNEIFY}/admin`,
  SUNEIFY_ADMIN_MAKERS: `${SUNEIFY}/admin/makers`,
  SUNEIFY_ADMIN_PRODUCTS: `${SUNEIFY}/admin/store/products`,
  SUNEIFY_ADMIN_ORDERS: `${SUNEIFY}/admin/store/orders`,
  SUNEIFY_ADMIN_LIVE: `${SUNEIFY}/admin/store/live`,
  SUNEIFY_ADMIN_VIDEOS: `${SUNEIFY}/admin/store/videos`,
  SUNEIFY_ADMIN_ORDER_SUMMARY: `${SUNEIFY}/admin/order-summary`,
  SUNEIFY_ADMIN_INFO: `${SUNEIFY}/admin/account-information`,
  SUNEIFY_ADMIN_INFO_EDIT: `${SUNEIFY}/admin/account-information/edit`,
  SUNEIFY_ADMIN_SUNE_TV: `${SUNEIFY}/admin/sune-tv/series`,
  SUNEIFY_ADMIN_SUNE_TV_EPISODES: `${SUNEIFY}/admin/sune-tv/series/:mode/:seriesId`,
  SUNEIFY_ADMIN_ONBOARD_SELLER: `${SUNEIFY}/admin/onboard-seller`
};

export const DISABLE_HEADER_NAV_ROUTES = [
  ROUTES.LOGIN,
  ROUTES.SIGNUP,
  ROUTES.RESET_PASSWORD_REQUEST,
  ROUTES.RESET_PASSWORD_OTP_VERIFICATION,
  ROUTES.RESET_PASSWORD_UPDATE,
  ROUTES.BUSINESS_INFORMATION,
  ROUTES.ADDITIONAL_INFORMATION,
  ROUTES.HOST_PERSONAL_INFORMATION,
  ROUTES.HOST_ADDITIONAL_INFORMATION
];
