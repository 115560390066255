import './index.css';
import './i18next/index';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import App from './pages/App';
import reportWebVitals from './reportWebVitals';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import AxiosInterceptor from './components/AxiosInterceptor';
import { AnalyticsProvider } from './context/AnalyticsProvider';
import { PerformanceProvider } from './context/PerformanceAnalyticsProvider';
import '@fontsource/inter';
import { RemoteConfigProvider } from './context/RemoteConfigProvider';
import { Provider } from 'react-redux';
import { store } from './reduxStore';
import { initializeSentry } from './sentry';
import { Experimental_CssVarsProvider as MaterialCssVarsProvider } from '@mui/material/styles';
import { extendTheme as extendJoyTheme, THEME_ID } from '@mui/joy/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { experimental_extendTheme as extendMaterialTheme } from '@mui/material/styles';
import { THEME_ID as MATERIAL_THEME_ID } from '@mui/material/styles';
import { CssVarsProvider as JoyCssVarsProvider } from '@mui/joy/styles';
import CssBaseline from '@mui/material/CssBaseline';

const joyTheme = extendJoyTheme();
const materialTheme = extendMaterialTheme();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      cacheTime: 0
    }
  }
});

initializeSentry();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <MaterialCssVarsProvider theme={{ [MATERIAL_THEME_ID]: materialTheme }} defaultMode="system">
    <JoyCssVarsProvider theme={{ [THEME_ID]: joyTheme }}>
      <CssBaseline enableColorScheme />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <BrowserRouter>
          <Provider store={store}>
            <RemoteConfigProvider>
              <AnalyticsProvider>
                <PerformanceProvider>
                  <QueryClientProvider client={queryClient}>
                    <AuthProvider>
                      <>
                        <AxiosInterceptor />
                        <App />
                      </>
                    </AuthProvider>
                  </QueryClientProvider>
                </PerformanceProvider>
              </AnalyticsProvider>
            </RemoteConfigProvider>
          </Provider>
        </BrowserRouter>
      </LocalizationProvider>
    </JoyCssVarsProvider>
  </MaterialCssVarsProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
